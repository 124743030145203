import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';
import { useLocation } from '@reach/router';

import { WEDDING_RESEPSI_TIME } from '../constants';
import { ENABLE_SHIFT_TIME_RECEPTION } from '../constants/feature-flags';

/**
 * custom hooks to return shift time type
 * @return {string}
 */
function useShiftTime() {
  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  const location = useLocation();
  const shiftType = getQueryValue(location, 'shift');

  let shift = 'Shift';

  if (shiftType == '1') {
    shift = 'Resepsi';
  } else if (shiftType == '2') {
    shift = 'Pemberkatan-Resepsi';
  }

  return shift;
}

export default useShiftTime;